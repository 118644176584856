import React, { TouchEvent, useCallback } from 'react'
import { IsMobileContext } from '../../common/Context'
import ArrowRight from '../../images/arrow_right.svg'
import ArrowLeft from '../../images/arrow_left.svg'
import { AnimationService } from '../../common/AnimationService'
import './style.scss'

type Props = {
  children: JSX.Element[]
  infiniteLoop?: boolean
  show?: number
  scrollStopTime?: number
  isNavShown?: boolean
}

const Carousel: React.FC<Props> = ({ children, show = 1, infiniteLoop = true, scrollStopTime = 0, isNavShown = true }) => {
  const { isMobile } = React.useContext(IsMobileContext)
  const arrowHeight = isMobile ? '6vw' : '1.5vw'

  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [length, setLength] = React.useState(children.length)
  const [touchPosition, setTouchPosition] = React.useState<null | number>(null)
  const [isRepeating, setIsRepeating] = React.useState<boolean>(infiniteLoop && children.length > show)
  const [transitionEnabled, setTransitionEnabled] = React.useState<boolean>(true)

  React.useEffect(() => {
    setLength(children.length)
    setIsRepeating(infiniteLoop && children.length > show)
  }, [children, infiniteLoop, show])

  React.useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) setTransitionEnabled(true)
    }
  }, [currentIndex, show, length, isRepeating])

  const onRightButtonClick = useCallback(() => {
    if (isRepeating || currentIndex < (length - show)) {
      setCurrentIndex(prevState => prevState + 1)
    }
  }, [isRepeating, currentIndex, length, show])

  const onLeftButtonClick = () => {
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1)
    }
  }

  React.useEffect(() => {
    let timer: ReturnType<typeof setInterval>
    if (scrollStopTime) {
      timer = setInterval(onRightButtonClick, scrollStopTime * 1000)
    }
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [scrollStopTime, onRightButtonClick])

  const handleTouchStart = (e: TouchEvent) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e: TouchEvent) => {
    const touchDown = touchPosition
    if (touchDown === null) return
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 5) onRightButtonClick()
    if (diff < -5) onLeftButtonClick()
    setTouchPosition(null)
  }

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex >= length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const renderExtraPrev = () => {
    const output = []
    for (let i = 0; i < show; i++) output.push(children[length - 1 - i])
    output.reverse()
    return output
  }

  const renderExtraNext = () => {
    const output = []
    for (let i = 0; i < show; i++) output.push(children[i])
    return output
  }

  const arrowsRef = React.useRef(null)
  React.useEffect(() => {
    if (isNavShown) {
      AnimationService.slideUp(arrowsRef.current)
    }
  }, [isNavShown])

  return (
    <div className='Carousel__container flex flex-col'>
      <div className='Carousel__wrapper flex flex-col w-100 relative'>
        <div
          className='Carousel__contentWrapper overflow-hidden w-100 h-100'
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`Carousel__content show-${show} flex transition-all`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
              transition: !transitionEnabled ? 'none' : undefined
            }}
            onTransitionEnd={handleTransitionEnd}
          >
            {(length > show && isRepeating) && renderExtraPrev()}
            {children}
            {(length > show && isRepeating) && renderExtraNext()}
          </div>
        </div>
        {
          isNavShown &&
          <div className='Arrows flex flex-row justify-between pl-mobile-xs w-mobile-l lg:w-xl lg:mb-m lg:pl-m' ref={arrowsRef}>
            <button className='ButtonLeft' onClick={onLeftButtonClick} >
              <ArrowLeft className='ArrowLeft' style={{ height: arrowHeight }} />
            </button>
            <button className='ButtonRight' onClick={onRightButtonClick}>
              <ArrowRight className='ArrowRight' style={{ height: arrowHeight }} />
            </button>
          </div>
        }
      </div>
    </div>
  )
}

export default Carousel