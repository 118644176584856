import * as React from 'react'
import Carousel from '../Carousel'
import Testimonial from '../Testimonial'
import { TestimonialsSection as TestimonialsSectionClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import './style.scss'

const TestimonialsSection: React.FC<TestimonialsSectionClass> = ({ heading, testimonials, navbarDarkMode }) => {

  const titleRef = React.useRef(null)
  React.useEffect(() => {
    AnimationService.slideUp(titleRef.current)
  }, [])

  return (
    <section className={`TestimonialsSection GlobSectPadSpacing max-w-full ${navbarDarkMode ? 'navbar-dark' : ''}`}>
      <div className="bg-black text-white py-mobile-l lg:pt-xl lg:pb-l">
        <h2 className='FontL block mb-mobile-s mx-mobile-xs lg:mx-m lg:mb-s' ref={titleRef}>{heading}</h2>
        <Carousel isNavShown={testimonials.length > 1}>
          {testimonials.map((testimonial, index) => {
            return <Testimonial {...testimonial} key={index} />
          })}
        </Carousel>
      </div>
    </section>
  )
}

export default TestimonialsSection
