import * as React from 'react'
import { AnimationService } from '../../common/AnimationService'
import { Testimonial as TestimonialClass } from './model'
import './style.scss'

const Testimonial: React.FC<TestimonialClass> = ({ text, author }) => {

  const textRef = React.useRef(null)
  const authorRef = React.useRef(null)
  React.useEffect(() => {
    AnimationService.slideUp(textRef.current)
    AnimationService.slideUp(authorRef.current)
  }, [])

  return (
    <article className="Testimonial my-auto">
      <p className='FontXL mb-mobile-s px-mobile-xs mt-0 lg:px-m lg:mb-s lg:mt-s' ref={textRef}>{text}</p>
      <p className='FontM mb-mobile-s px-mobile-xs lg:px-m lg:mb-s' ref={authorRef}>{author}</p>
    </article>
  )
}

export default Testimonial
