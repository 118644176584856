import * as React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export const useNavbarDarkMode = (isMobile: boolean, navbarRef: React.RefObject<HTMLElement>) => {
  const [navbarInDarkMode, setNavbarInDarkMode] = React.useState(false)

  React.useEffect(() => {
    let scrollTrigger: ScrollTrigger
    let isMounted = true
    if (typeof document !== 'undefined' && navbarRef.current) {
      const navHeight = navbarRef.current.clientHeight
      if (navHeight === 0) {
        return
      }
      const triggerPoint = (navHeight / 2).toFixed(0)
      const pageSectionsSelector = `${isMobile ? '.mobile-page' : '.desktop-page'} > header + main > section`
      const elements: HTMLElement[] = gsap.utils.toArray(pageSectionsSelector)
      elements.forEach(element => {
        if (element.classList.contains('navbar-dark')) {
          scrollTrigger = ScrollTrigger.create({
            trigger: element.firstElementChild,
            start: `top ${triggerPoint}`,
            end: `bottom ${triggerPoint}`,
            onToggle: self => {
              if (isMounted) {
                setNavbarInDarkMode(self.isActive)
              }
            }
          })
        }
      })
    }

    return () => {
      isMounted = false
      if (scrollTrigger) {
        scrollTrigger.kill()
      }
    }
  }, [navbarRef, isMobile])

  return {
    navbarInDarkMode 
  }
}
