import { graphql } from 'gatsby'
import _get from 'lodash/get'

/* Kentico model: Testimonial Element */
export interface KenticoTestimonialElement extends KontentItem {
  elements: {
    quote: KontentTextElement
    signature: KontentTextElement
  }
}

export class Testimonial {
  text: string
  author: string

  constructor(rawData: KenticoTestimonialElement) {
    this.text = _get(rawData, 'elements.quote.value', '')
    this.author = _get(rawData, 'elements.signature.value', '')
  }
}

export const testimonialFragment = graphql`
  fragment kontent_item_modular_content_element_valueFragment on kontent_item_modular_content_element_value {
  value {
    ... on kontent_item_testimonial {
      elements {
        quote {
          value
        }
        signature {
          value
        }
      }
    }
  }
}`