import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import { Testimonial, KenticoTestimonialElement } from '../Testimonial/model'
import _get from 'lodash/get'

/* Kentico model: Testimonials Section */
export interface KenticoTestimonialsSection extends KontentItem {
  heading: KontentTextElement
  quotes: KenticoTestimonialElement[]
  navbar_dark_mode: KontentTextElement
}

export class TestimonialsSection {
  heading: string
  testimonials: Testimonial[]
  navbarDarkMode: boolean

  constructor(data: KenticoTestimonialsSection) {
    this.heading = _get(data, 'heading.value', '')
    this.testimonials = _get(data, 'quotes.value', [])
      .filter(Boolean)
      .map((element: KenticoTestimonialElement) => {
        return new Testimonial(element)
      })
    this.navbarDarkMode = _get(data, 'navbar_dark_mode.value[0].name', '') === 'Yes'
  }
}

export const testimonialsSectionFragment = graphql`    
  query TestimonialsSectionQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_testimonial_section {
                elements {
                  ...kontent_item_testimonial_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`

export const testimonialsSectionElementsFragment = graphql`
  fragment kontent_item_testimonial_section_elementsFragment on kontent_item_testimonial_section_elements {
    heading {
      value
    }
    quotes {
      ...kontent_item_modular_content_element_valueFragment
    }
    navbar_dark_mode {
      value {
        name
      }
    }
  }
`

export const testimonialsFragment = graphql`
  fragment kontent_item_testimonials_fragment on kontent_item_modular_content_element_value {
    value {
      ... on kontent_item_testimonial_section {
        elements {
          ...kontent_item_testimonial_section_elementsFragment
        }
        preferred_language
        system {
          codename
        }
      }
    }
  }
`

export const useTestimonialsSectionsData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(testimonialsSectionFragment)
  const testimonialsSectionData: KenticoTestimonialsSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new TestimonialsSection(testimonialsSectionData)
}

export const useTestimonialsDynamicData = (props: PageProps, path: string) => {
  return _get(props, `${path}.testimonials_section.value`).length
    ? new TestimonialsSection(_get(props, `${path}.testimonials_section.value[0].elements`))
    : null
}
