import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import LinksGrid from '../LinksGrid'
import { LinksSection as LinksSectionClass } from './model'
import './style.scss'

interface LinksSectionProps extends LinksSectionClass {
  className?: string
}

const LinksSection: React.FC<LinksSectionProps> = ({
  linksColumns,
  className,
}) => {
  const { isMobile } = React.useContext(IsMobileContext)
  if (isMobile) {
    return null
  }

  return (
    <section className={`LinksSection ${className ? className : 'lg:pt-xl lg:pb-l'}`}>
      <LinksGrid
        linksColumns={linksColumns}
        textColor="text-black"
        borderColor="border-separator border-opacity-separator"
        linkHoverColor="text-black"
        animate
      />
    </section>
  )
}

export default LinksSection
